/*!

 =========================================================
 * Team SVT DFW v1.9.0 based on Material Kit Free - v2.0.2 (Bootstrap 4.0.0 Final Edition)
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-kit-react
 * Copyright 2020 Creative Tim (https://www.creative-tim.com)
 * Licensed under MIT (https://github.com/creativetimofficial/material-kit-react/blob/master/LICENSE.md)

 * Coded by Creative Tim

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

 */
@import "core/variables";
@import "core/mixins";

// Core Components
@import "core/misc";

// Core Plugins

// @import "plugins/plugin-datetime-picker";
@import "plugins/plugin-nouislider";
@import "plugins/plugin-react-datetime";
@import "plugins/plugin-react-slick";

@import url(//db.onlinewebfonts.com/c/f37f6cae3a3995aac6d299a1f65eed21?family=Futura);

@font-face {font-family: "Futura"; src: url("//db.onlinewebfonts.com/t/f37f6cae3a3995aac6d299a1f65eed21.eot"); 
    src: url("//db.onlinewebfonts.com/t/f37f6cae3a3995aac6d299a1f65eed21.eot?#iefix") format("embedded-opentype"), 
    url("//db.onlinewebfonts.com/t/f37f6cae3a3995aac6d299a1f65eed21.woff2") format("woff2"), 
    url("//db.onlinewebfonts.com/t/f37f6cae3a3995aac6d299a1f65eed21.woff") format("woff"), 
    url("//db.onlinewebfonts.com/t/f37f6cae3a3995aac6d299a1f65eed21.ttf") format("truetype"), 
    url("//db.onlinewebfonts.com/t/f37f6cae3a3995aac6d299a1f65eed21.svg#Futura") format("svg"); }

* {
    font-family: Futura;
}

[class*="Root-container"] {
    background-color: transparent
}

#team_svt_logo{
    width: 225px;
}
